import * as React from "react";
import { CircularProgress } from "@mui/material";
import { CenteredBox, CenteredBoxProps } from "./CenteredBox";

interface Props extends Omit<CenteredBoxProps, "children"> {
    hidden?: boolean;
}

export const CenteredProgress = (p: Props) => {
    return (
        <CenteredBox
            {...{
                ...p,
                sx: {
                    display: p.hidden ? "none" : "flex",
                    ...p.sx,
                },
            }}
        >
            <CircularProgress />
        </CenteredBox>
    );
};
