import * as React from "react";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { Box } from "@mui/material";

export interface CenteredBoxProps {
    children: React.ReactNode;
    fullHeight?: boolean;
    sx?: SxProps<Theme>;
}

export const CenteredBox = (p: CenteredBoxProps) => {
    const fullHeight = p.fullHeight || false;
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: fullHeight ? "100%" : "auto",
                ...p.sx,
            }}
        >
            {p.children}
        </Box>
    );
};
