import { Option } from "fp-ts/Option";
import { GlobalAlert } from "../../common/GlobalAlerts";
import * as React from "react";
import { useSupabase } from "../../../utils/supabase/useSupabase";
import { assertUnreachable } from "../../../utils/assertUnreachable";
import { SupabaseClient } from "@supabase/supabase-js";
import { useForm } from "../../form/form";
import { flow, pipe } from "fp-ts/function";
import {
    executeSupabaseQuery,
    queryErrorToDefaultMessage,
    rpc,
} from "../../../utils/supabase/useQuery";
import * as t from "io-ts";
import { either, option, record, task } from "fp-ts";
import { runTask, runTaskWithLoadingState } from "../../../utils/fp";
import { GoogleIcon } from "./GoogleIcon";
import { LoadingButton } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import {
    passwordInputCodec,
    passwordInputCodecMessages,
} from "../../../domain/sign";
import { Box, Button, Grid, Typography } from "@mui/material";
import { login, RedirectTo } from "../../../routes/user";
import { NextRouter } from "next/router";
import { resolveAs } from "../../../utils/routing/routing";
import { reportError } from "../../../utils/reporting";
import { PasswordInput } from "../../form/PasswordInput";
import { Email, emailCodec, trimmedStringCodec } from "../../../domain/common";
import { NonEmptyString } from "io-ts-types";
import { PasswordManagerUsernameInput } from "../../form/PasswordManagerUsernameInput";
import { Input } from "../../form/Input";

interface InitState {
    type: "init";
    email?: Email;
}
interface EmailKnownState {
    type: "emailKnown";
    email: Email;
}
interface EmailUnKnownState {
    type: "emailUnknown";
    email: Email;
}
interface PasswordResetRequestedState {
    type: "passwordResetRequested";
    email: Email;
}
interface EmailSignUpCompleteState {
    type: "emailSignUpComplete";
    email: Email;
}
type State =
    | InitState
    | EmailKnownState
    | EmailUnKnownState
    | PasswordResetRequestedState
    | EmailSignUpCompleteState;

interface CommonProps {
    setAlert: (alert: Option<GlobalAlert>) => void;
    redirectTo: Option<RedirectTo>;
    router: NextRouter;
}

export const Sign = (p: CommonProps) => {
    const [state, setState] = React.useState<State>({
        type: "init",
    });

    const supabase = useSupabase();

    switch (state.type) {
        case "init":
            return (
                <SignInit
                    supabase={supabase}
                    setState={setState}
                    defaultEmail={state.email}
                    {...p}
                />
            );

        case "emailKnown":
            return (
                <KnownEmail
                    supabase={supabase}
                    email={state.email}
                    setState={setState}
                    {...p}
                />
            );

        case "emailUnknown":
            return (
                <UnknownEmail
                    supabase={supabase}
                    email={state.email}
                    setState={setState}
                    {...p}
                />
            );

        case "passwordResetRequested":
            return (
                <Typography>
                    Na emailovou adresou&nbsp;<strong>{state.email}</strong>{" "}
                    během chvíle obdržíte odkaz k&nbsp;obnově hesla. Pokračujte
                    kliknutím na něj.
                </Typography>
            );

        case "emailSignUpComplete":
            return (
                <Typography>
                    Na emailovou adresou&nbsp;<strong>{state.email}</strong>{" "}
                    během chvíle obdržíte odkaz k&nbsp;dokončení registrace.
                    Pokračujte kliknutím na něj.
                </Typography>
            );
    }

    assertUnreachable(state);
};

interface SignInitProps extends CommonProps {
    supabase: SupabaseClient;
    setState: React.Dispatch<React.SetStateAction<State>>;
    defaultEmail?: Email;
}

const SignInit = (p: SignInitProps) => {
    const [loading, setLoading] = React.useState(false);

    const form = useForm(
        initFormDef,
        (data) => {
            pipe(
                rpc(
                    "is_email_used",
                    {
                        p_email: data.email,
                    },
                    t.boolean,
                    p.supabase
                ),
                task.map(
                    either.fold(
                        flow(
                            queryErrorToDefaultMessage,
                            (message): GlobalAlert => ({
                                message,
                                severity: "error",
                            }),
                            option.some,
                            p.setAlert
                        ),
                        (emailKnown) => {
                            p.setAlert(option.none);

                            switch (emailKnown) {
                                case true:
                                    p.setState({
                                        type: "emailKnown",
                                        email: data.email,
                                    });
                                    return;

                                case false:
                                    p.setState({
                                        type: "emailUnknown",
                                        email: data.email,
                                    });
                                    return;
                            }

                            assertUnreachable(emailKnown);
                        }
                    )
                ),
                runTaskWithLoadingState(setLoading)
            );
        },
        { email: p.defaultEmail }
    );

    const signMethodPaddingLeft = {
        paddingLeft: { sm: 1 },
        paddingRight: { sm: 2 },
    };

    return (
        <Grid container direction={"column"} spacing={3}>
            <Grid item>
                <SignMethodLabel>
                    Máte google učet? Nejsnadněji se přihlásíte pomocí něj
                </SignMethodLabel>
                <Box sx={signMethodPaddingLeft}>
                    <Button
                        startIcon={<GoogleIcon />}
                        variant={"contained"}
                        onClick={() => {
                            pipe(
                                executeSupabaseQuery(
                                    "googleSign",
                                    t.null,
                                    (supabase) =>
                                        supabase.auth.signIn(
                                            {
                                                provider: "google",
                                            },
                                            {
                                                redirectTo: redirectToFullUrl(
                                                    p.redirectTo,
                                                    p.router
                                                ),
                                            }
                                        ),
                                    p.supabase
                                ),
                                task.map(
                                    flow(
                                        either.fold(
                                            flow(
                                                queryErrorToDefaultMessage,
                                                (message): GlobalAlert => ({
                                                    message,
                                                    severity: "error",
                                                }),
                                                option.some
                                            ),
                                            () => option.none
                                        ),
                                        p.setAlert
                                    )
                                ),
                                runTask
                            );
                        }}
                    >
                        Přihlásit se přes Google
                    </Button>
                </Box>
            </Grid>
            <Grid item>
                <SignMethodLabel>
                    Nebo se přihlaste pomocí e-mailové adresy
                </SignMethodLabel>
                <form {...form.formProps}>
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            ...signMethodPaddingLeft,
                            alignItems: "start",
                        }}
                    >
                        <Grid item xs={12} md={8}>
                            <Input
                                {...form.req.email}
                                label={"Email"}
                                autoComplete={"username"}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <LoadingButton
                                sx={{
                                    height: "100%",
                                }}
                                variant={"contained"}
                                type={"submit"}
                                loading={loading}
                                size={"large"}
                            >
                                Pokračovat
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
};
const initFormDef = {
    required: {
        email: {
            codec: trimmedStringCodec.pipe(emailCodec),
        },
    },
    optional: {},
};

interface Props {
    children?: React.ReactNode;
}
const SignMethodLabel = (p: Props) => (
    <Typography
        variant={"subtitle1"}
        sx={{ marginBottom: (theme) => theme.spacing(1) }}
    >
        {p.children}
    </Typography>
);

interface EmailInputEditBtnProps {
    email: Email;
    setState: React.Dispatch<React.SetStateAction<State>>;
}

const EmailInputGiven = (p: EmailInputEditBtnProps) => (
    <Button
        variant={"outlined"}
        endIcon={<EditIcon />}
        onClick={() => {
            p.setState({
                type: "init",
                email: p.email,
            });
        }}
    >
        {p.email}
    </Button>
);

interface UnknownEmailProps extends CommonProps {
    supabase: SupabaseClient;
    email: Email;
    setState: React.Dispatch<React.SetStateAction<State>>;
}
const UnknownEmail = (p: UnknownEmailProps) => {
    const [loading, setLoading] = React.useState(false);

    const form = useForm(
        unknownFormDef,
        (data) => {
            if (data.newPasswordConfirm !== data.newPassword) {
                form.formHooks.setError("newPasswordConfirm", {
                    message: "Hesla se neshodují",
                });
            } else {
                pipe(
                    executeSupabaseQuery(
                        "signUp",
                        t.unknown, // not needed and the response changes over versions...
                        (supabase) =>
                            supabase.auth.signUp(
                                {
                                    email: data.email,
                                    password: data.newPassword,
                                },
                                {
                                    redirectTo: redirectToFullUrl(
                                        p.redirectTo,
                                        p.router
                                    ),
                                }
                            ),
                        p.supabase
                    ),
                    task.map(
                        either.fold(
                            (e) => {
                                reportError("sign with unknown email", e);

                                pipe(
                                    e,
                                    queryErrorToDefaultMessage,
                                    (message): GlobalAlert => ({
                                        message,
                                        severity: "error",
                                    }),
                                    option.some,
                                    p.setAlert
                                );
                            },
                            () => {
                                p.setAlert(option.none);
                                p.setState({
                                    type: "emailSignUpComplete",
                                    email: data.email,
                                });
                            }
                        )
                    ),
                    runTaskWithLoadingState(setLoading)
                );
            }
        },
        {
            email: p.email,
        }
    );

    return (
        <Box>
            <Typography
                variant={"h5"}
                component={"div"}
                sx={{
                    marginTop: (theme) => theme.spacing(1),
                    marginBottom: (theme) => theme.spacing(2),
                }}
            >
                Vytvořte si nový účet
            </Typography>
            <form {...form.formProps}>
                <Grid container direction={"column"} spacing={2}>
                    <Grid item>
                        <Input
                            {...form.req.email}
                            label={"Email"}
                            autoComplete={"username"}
                        />
                    </Grid>
                    <Grid item>
                        <PasswordInput
                            {...form.req.newPassword}
                            autoComplete={"new-password"}
                            label={"Heslo"}
                        />
                    </Grid>
                    <Grid item>
                        <PasswordInput
                            {...form.req.newPasswordConfirm}
                            autoComplete={"new-password"}
                            label={"Heslo znovu"}
                        />
                    </Grid>
                    <Grid item>
                        <LoadingButton
                            type={"submit"}
                            loading={loading}
                            variant={"contained"}
                        >
                            Vytvořit účet
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

const unknownFormDef = {
    required: {
        email: {
            codec: trimmedStringCodec.pipe(emailCodec),
        },
        newPassword: {
            codec: trimmedStringCodec.pipe(passwordInputCodec), // trim should be used in login form as well!
            messages: passwordInputCodecMessages,
            helperText: pipe(
                passwordInputCodecMessages,
                record.map((msg) => msg())
            ),
        },
        newPasswordConfirm: { codec: trimmedStringCodec.pipe(t.string) },
    },
    optional: {},
};

interface KnownEmailProps extends CommonProps {
    supabase: SupabaseClient;
    email: Email;
    setState: React.Dispatch<React.SetStateAction<State>>;
}

const knownEmailFormDef = {
    required: {
        // trim is ok unless is used on input as well! no stricter validation used -> should be used on new password input only -> validation rules can change over time so this will make all stored password invalid
        password: { codec: trimmedStringCodec.pipe(NonEmptyString) },
    },
    optional: {},
};
const KnownEmail = (p: KnownEmailProps) => {
    const [signInLoading, setSignInLoading] = React.useState(false);
    const form = useForm(knownEmailFormDef, (data) => {
        pipe(
            executeSupabaseQuery(
                "emailSignIn",
                t.unknown,
                (supabase) =>
                    supabase.auth.signIn(
                        {
                            email: p.email,
                            password: data.password,
                        },
                        {
                            redirectTo: redirectToFullUrl(
                                p.redirectTo,
                                p.router
                            ),
                        }
                    ),
                p.supabase
            ),
            task.map(
                flow(
                    either.fold(
                        (e) => {
                            reportError("sign with known email", e);

                            return pipe(
                                e,
                                (e): GlobalAlert["message"] => {
                                    switch (e.kind) {
                                        case "dbError":
                                            switch (e.message) {
                                                case "Invalid email or password":
                                                    return "Zkontrolujte prosím vaše přihlašovací údaje a zkuste to znovu.";

                                                case "Email not confirmed":
                                                    return (
                                                        <Box>
                                                            Ověřte email
                                                            kliknutím na odkaz,
                                                            který jsme vám na
                                                            něj poslali. Nebo si
                                                            heslo{" "}
                                                            <PasswordResetBtn
                                                                {...p}
                                                            >
                                                                resetujte
                                                            </PasswordResetBtn>
                                                            .
                                                        </Box>
                                                    );
                                            }

                                            break;
                                    }

                                    return e.message;
                                },
                                (message): Option<GlobalAlert> =>
                                    option.some({
                                        severity: "error",
                                        message,
                                    })
                            );
                        },
                        () => option.none
                    ),
                    p.setAlert
                )
            ),
            runTaskWithLoadingState(setSignInLoading)
        );
    });

    return (
        <>
            <Box sx={{ marginBottom: (theme) => theme.spacing(2) }}>
                <EmailInputGiven email={p.email} setState={p.setState} />
            </Box>

            <form {...form.formProps}>
                <PasswordManagerUsernameInput value={p.email} />
                <Grid container direction={"column"} spacing={2}>
                    <Grid item>
                        <PasswordInput
                            {...form.req.password}
                            label={"Heslo"}
                            autoComplete={"current-password"}
                        />
                    </Grid>
                    <Grid item>
                        <LoadingButton
                            loading={signInLoading}
                            type={"submit"}
                            variant={"contained"}
                        >
                            Přihlásit se
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>

            <Box
                sx={{
                    marginTop: (theme) => theme.spacing(3),
                }}
            >
                <PasswordResetBtn {...p}>
                    Zapomněli jste heslo?
                </PasswordResetBtn>
            </Box>
        </>
    );
};

interface PasswordResetBtnProps extends CommonProps {
    children: React.ReactNode;
    email: Email;
    supabase: SupabaseClient;
    setState: React.Dispatch<React.SetStateAction<State>>;
}

const PasswordResetBtn = (p: PasswordResetBtnProps) => {
    const [passwordResetLoading, setPasswordResetLoading] =
        React.useState(false);

    return (
        <LoadingButton
            variant={"text"}
            loading={passwordResetLoading}
            onClick={() => {
                pipe(
                    executeSupabaseQuery(
                        "passwordReset",
                        t.type({}),
                        (supabase) =>
                            supabase.auth.api.resetPasswordForEmail(p.email, {
                                redirectTo: redirectToFullUrl(
                                    p.redirectTo,
                                    p.router
                                ),
                            }),
                        p.supabase
                    ),
                    task.map(
                        either.fold(
                            (e) => {
                                p.setAlert(
                                    option.some({
                                        severity: "error",
                                        message: queryErrorToDefaultMessage(e),
                                    })
                                );
                            },
                            () => {
                                p.setAlert(option.none);
                                p.setState({
                                    type: "passwordResetRequested",
                                    email: p.email,
                                });
                            }
                        )
                    ),
                    runTaskWithLoadingState(setPasswordResetLoading)
                );
            }}
        >
            {p.children}
        </LoadingButton>
    );
};

const redirectToFullUrl = (
    redirectTo: Option<RedirectTo>,
    router: NextRouter
) =>
    pipe(
        redirectTo,
        option.chain((to) =>
            resolveAs(
                login.route({
                    redirectTo: to,
                }),
                router
            )
        ),
        option.map((path) => {
            const fullUrl = new URL(
                path,
                window.location.protocol + window.location.host
            ).toString();

            console.log("redirectToFullUrl", redirectTo, fullUrl);
            return fullUrl;
        }),
        option.toUndefined
    );
